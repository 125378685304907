import './Footer.css'

function Footer() {
  return (
    <div className="footer">
        this is my footer
    </div>
  );
}

export default Footer;
