// import logo from './logo.svg';
import './App.css';
import Footer from './components/Footer';
import * as React from 'react';
// import ReactDOM from 'react-dom';
import Button from '@mui/material/Button';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src="https://media.discordapp.net/attachments/717503169214808095/914776758690127892/31943c94-083b-4aea-b3b4-d7616c0fed4c.png?width=632&height=632" className="App-logo" alt="logo" />
        <p>
        this website is work in progress i am just learning
        <br/>this is alec's nft it is worth nothing
        </p>
        <Button variant = "contained" color="secondary"> This button does nothing </Button>
        <br/>
        <a
          className="App-link"
          href="https://kevinhsu.tech"
          target="_blank"
          rel="noopener noreferrer"
        >
          better website (kevin's)
        </a>
      </header>
      <Footer/>

    </div>
  );
}


export default App;
